<template>
  <v-card :class="{ 'd-print-none': hideEmpty && invoices.length == 0 }">
    <v-card-title class="text-center">
      Storage invoices

      <span class="float-right d-print-none">
        <v-btn
          id="btn-invoice"
          v-can="'storage.invoice.create'"
          v-tooltip="'Bill storage period'"
          :data-storage-id="storage.id"
          data-target="btn-invoice"
          data-ajax-type="newS"
          icon="mdi-receipt-text-plus"
          variant="text"
        />
      </span>
    </v-card-title>

    <v-card-text v-if="invoices.length">
      <v-table density="compact">
        <thead>
          <tr>
            <th>Invoice number</th>
            <th class="text-right">Balance</th>
            <th class="text-right">Total</th>
            <th>Description</th>
            <th class="text-right">Date created</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="invoice in invoices" :key="invoice.id">
            <td>
              <a
                :href="'/invoice/' + invoice.id"
                class="font-weight-bold text-reset"
              >
                {{ invoice.invoice_number }}
              </a>
            </td>

            <td class="text-right">
              <span>{{ formatMoney(invoice.balance) }}</span>
            </td>

            <td class="text-right">
              <span>{{ formatMoney(invoice.total) }}</span>
            </td>

            <td>
              <template v-for="item in invoice.items" :key="item.id">
                <div v-if="item.from">
                  {{ formatDate(item.from, 'shortDate') }} -
                  {{ formatDate(item.to, 'shortDate') }}
                </div>

                <div v-for="cost in item.items" :key="cost.id">
                  {{ cost.service.name }} - {{ formatMoney(cost.price) }}
                </div>
              </template>
            </td>

            <td class="text-right">
              {{ formatDate(invoice.created_at, 'shortDate') }}
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { storage } from '@/store/storage/storage.js';
import { getStorageInvoices } from '@/services/api/storage.js';
import { ref, watch } from 'vue';
import { format as formatDate } from '@/utils/formatter/date.js';
import { formatMoney } from '@/utils/formatter/number.js';

defineProps({
  hideEmpty: {
    type: Boolean,
    default: false,
  },
});

const invoices = ref([]);
watch(storage, fetchInvoices);

function fetchInvoices() {
  if (!storage.id) {
    setTimeout(() => {
      if (!storage.id) {
        console.error('Storage ID is not set');
      }
    }, 1000);

    return;
  }

  getStorageInvoices(storage.id).then((_invoices) => {
    invoices.value = _invoices;
  });
}

fetchInvoices();
</script>
