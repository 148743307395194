<template>
  <div
    class="p-1 border m-1 d-flex justify-center align-center bg-light position-relative"
    style="width: 110px; height: 110px"
  >
    <img
      v-if="attachment.thumbnailUrl"
      data-target="attachment-viewer"
      class="cursor-pointer"
      :data-src="attachment.url"
      :src="attachment.thumbnailUrl ?? defaultImage"
      :alt="attachment.name"
      style="max-width: 100px; max-height: 100px"
    />
    <div
      v-else
      :data-src="attachment.url"
      data-target="attachment-viewer"
      class="cursor-pointer d-flex justify-center align-center"
      style="width: 100px; height: 100px"
    >
      <v-icon size="100">mdi-{{ attachment.icon }}</v-icon>
    </div>

    <v-btn
      v-can="permissions?.deletePermission"
      v-tooltip="'Delete'"
      class="position-absolute d-print-none"
      style="bottom: 4px; right: 4px"
      size="small"
      variant="plain"
      icon="mdi-delete"
      color="red"
      @click="deleteAttachment(attachment.id)"
    />
  </div>
</template>

<script setup>
const defaultImage = '/images/placeholder.png';

defineProps({
  attachment: {
    type: Object,
    required: true,
  },
  permissions: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits('delete');

/**
 * @param {number} id
 */
function deleteAttachment(id) {
  emit('delete', id);
}
</script>
