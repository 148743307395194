<template>
  <v-card
    v-can="permissions?.viewPermission"
    :class="{ 'd-print-none': hideEmpty && attachments.length == 0 }"
  >
    <v-card-title class="text-center">
      <span class="float-left d-print-none">
        <v-btn
          variant="text"
          :icon="viewGridMode ? 'mdi-view-grid' : 'mdi-view-list'"
          @click="viewGridMode = !viewGridMode"
        />
      </span>
      <v-icon icon="mdi-paperclip" />
      Attachments

      <span class="float-right d-print-none">
        <UploadFormModal
          v-if="$gates.hasPermission(permissions?.uploadPermission)"
          :url="uploadUrl"
          @uploaded="fetchAttachments"
        />
      </span>
    </v-card-title>

    <DeleteButton
      v-if="deleteUrl"
      v-model="deleteModal"
      :url="deleteUrl"
      no-button
      @deleted="fetchAttachments"
    />

    <v-card-text
      class="mb-4"
      style="max-height: 300px; min-height: 50px; overflow-y: auto"
    >
      <div
        v-if="viewGridMode"
        class="d-flex justify-space-around mb-2 flex-wrap"
      >
        <template v-for="attachment in attachments" :key="attachment.id">
          <AttachmentThumbnail
            :attachment="attachment"
            :permissions="permissions"
            @delete="deleteAttachment"
          />
        </template>
      </div>
      <v-row v-else>
        <template v-for="attachment in attachments" :key="attachment.id">
          <v-col cols="12">
            <div class="d-flex d-flex-nowrap">
              <div class="flex-shrink-1">
                <AttachmentThumbnail
                  :attachment="attachment"
                  :permissions="permissions"
                  @delete="deleteAttachment"
                />
              </div>

              <div class="flex-grow-1">
                <div class="d-flex align-items-center ma-2">
                  <v-table>
                    <tr>
                      <th>Name:</th>
                      <td>
                        {{ attachment.name || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th>Description:</th>
                      <td>
                        {{ attachment.description || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th>Created at:</th>
                      <td>
                        {{ formatDate(attachment.created_at, 'shortDateTime') }}
                      </td>
                    </tr>
                    <tr>
                      <th>Created by:</th>
                      <td>
                        <UserLink
                          v-if="attachment.createdBy"
                          :user="attachment.createdBy"
                          bold
                        />
                        <span v-else>Unknown</span>
                      </td>
                    </tr>
                  </v-table>
                </div>
              </div>
            </div>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import DeleteButton from '@/components/general/DeleteButton.vue';
import UserLink from '@/components/links/UserLink.vue';
import UploadFormModal from '@/components/modal/general/UploadFormModal.vue';
import AttachmentThumbnail from '@/components/card/general/AttachmentThumbnail.vue';
import { format as formatDate } from '@/utils/formatter/date.js';
import { getAttachments } from '@/services/api/attachment';
import { ref, computed } from 'vue';

const attachments = ref([]);
const selectedAttachment = ref(null);
const viewGridMode = ref(true);

const deleteModal = ref(false);

const { sectionId, section, child, childId } = defineProps({
  sectionId: {
    type: Number,
    required: true,
  },

  section: {
    type: String,
    required: true,
  },

  childId: {
    type: Number,
    default: null,
  },

  child: {
    type: String,
    default: null,
  },

  hideEmpty: {
    type: Boolean,
    default: false,
  },
});

const sectionPlural = computed(() => section + 's');

const childPlural = computed(() => {
  if (!childId) {
    return null;
  }

  switch (child) {
    case 'summary':
      return 'summaries';
    default:
      return child + 's';
  }
});

const deleteUrl = computed(() => {
  if (!selectedAttachment.value) {
    return null;
  }

  return route('api.v1.attachments.destroy', [selectedAttachment.value?.id]);
});

const uploadUrl = computed(() => {
  if (childId) {
    return route(
      'api.v1.' +
        sectionPlural.value +
        '.' +
        childPlural.value +
        '.files.upload',
      [sectionId, childId]
    );
  }

  return route('api.v1.' + sectionPlural.value + '.files.upload', [sectionId]);
});

const permissions = computed(() => {
  const permission = childId ? section + '.' + child : section;

  return {
    uploadPermission: permission + '.file.upload',
    viewPermission: permission + '.file.view',
    deletePermission: permission + '.file.delete',
  };
});

function fetchAttachments() {
  getAttachments({
    section: sectionPlural.value,
    sectionId,
    child: childPlural.value,
    childId,
  }).then((_attachments) => {
    attachments.value = _attachments;
  });
}

/**
 * @param {number} attachmentId
 */
function deleteAttachment(attachmentId) {
  selectedAttachment.value = attachments.value.find(
    (attachment) => attachment.id === attachmentId
  );

  if (selectedAttachment.value) {
    deleteModal.value = true;
  }
}

fetchAttachments();
</script>
