<template>
  <v-card :class="{ 'd-none': hideEmpty && !notes.length }">
    <v-card-title class="text-center">
      Storage notes

      <span class="float-right d-print-none">
        <v-btn
          id="btn-note"
          v-can="'storage.note.create'"
          v-tooltip="'Add note'"
          data-toggle="modal"
          data-target="#uppersite-menu"
          ajaxtype="newS"
          :storageid="storage.id"
          icon="mdi-message-plus"
          variant="text"
        />
      </span>
    </v-card-title>

    <v-card-text v-if="notes.length">
      <DeleteButton
        v-if="deleteUrl"
        v-model="deleteModal"
        :url="deleteUrl"
        no-button
        @deleted="fetchNotes"
      />

      <v-table density="compact">
        <thead>
          <tr>
            <th>Author</th>
            <th>Message</th>
            <th class="text-right">Date</th>
            <th
              v-can:any="'storage.note.update|storage.note.delete'"
              class="text-right d-print-none"
            >
              Options
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="note in notes" :key="note.id">
            <td>
              <UserLink :user="note.createdBy" bold />
            </td>

            <td>
              <span>{{ note.message }}</span>
            </td>

            <td class="text-right">
              {{ formatDate(note.date, 'shortDateTime') }}
            </td>

            <td
              v-can:any="'storage.note.update|storage.note.delete'"
              class="text-right d-print-none"
            >
              <v-menu>
                <template #activator="{ props: aProps }">
                  <v-btn
                    icon="mdi-dots-vertical"
                    v-bind="aProps"
                    variant="text"
                  />
                </template>

                <v-list>
                  <v-list-item v-can="'storage.note.update'">
                    <v-btn
                      id="btn-note"
                      data-toggle="modal"
                      data-target="#uppersite-menu"
                      ajaxtype="edit"
                      :noteid="note.id"
                      variant="text"
                      color="blue"
                      text="Edit"
                      block
                    />
                  </v-list-item>

                  <v-list-item v-can="'storage.note.delete'">
                    <v-btn
                      color="red"
                      variant="text"
                      text="Delete"
                      block
                      @click="deleteNote(note.id)"
                    />
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { storage } from '@/store/storage/storage.js';
import { getStorageNotes } from '@/services/api/storage.js';
import { computed, ref, watch } from 'vue';
import { format as formatDate } from '@/utils/formatter/date.js';
import DeleteButton from '@/components/general/DeleteButton.vue';
import UserLink from '@/components/links/UserLink.vue';

defineProps({
  hideEmpty: {
    type: Boolean,
    default: false,
  },
});

const notes = ref([]);
const selectedNote = ref(null);
const deleteModal = ref(false);
watch(storage, fetchNotes);

const deleteUrl = computed(() => {
  if (!selectedNote.value || !storage.id) {
    return null;
  }

  return route('api.v1.storages.notes.destroy', [
    storage.id,
    selectedNote.value?.id,
  ]);
});

function fetchNotes() {
  if (!storage.id) {
    setTimeout(() => {
      if (!storage.id) {
        console.error('Storage ID is not set');
      }
    }, 1000);

    return;
  }

  getStorageNotes(storage.id).then((_notes) => {
    notes.value = _notes;
  });
}

/**
 * @param {number} noteId
 */
function deleteNote(noteId) {
  selectedNote.value = notes.value.find((note) => note.id === noteId);

  if (selectedNote.value) {
    deleteModal.value = true;
  }
}

fetchNotes();
</script>
